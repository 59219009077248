/**
 * Author:    Kevin Matthews
 * Created:   01-25-2021
 *
 * Source code for Velvet Brown's personal webiste, created with GatsbyJS for deployment to Netlify
 *
 * https://kmatthews.dev/
 *
 **/

import React, { useEffect, useState } from 'react'
import { navigate, graphql } from 'gatsby'

// https://material-ui.com/
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'

import Layout from '../components/layout'

// Images
import vb2 from '../images/vb2.jpg'
import psuaa from '../images/icons/psuaa.png'
import peabody from '../images/icons/peabody.png'
import wick from '../images/icons/wick.png'
import buffet from '../images/icons/buffet.png'
import stiletto from '../images/icons/stiletto.png'

const IndexPage = ({ data, location }) => {
  const [posts, setPosts] = useState([])

  // Get blogs
  useEffect(() => {
    const posts = data.allMarkdownRemark.nodes

    const blogElements = []

    posts.forEach((post) => {
      blogElements.push(
        <div className='blogItem'>
          <Card
            className='mx-auto w-11/12 md:w-8/12 xl:w-6/12'
            onClick={() =>
              post.frontmatter.useLink
                ? window.open(post.frontmatter.link, '_blank')
                : navigate(post.frontmatter.slug)
            }
            variant='outlined'
          >
            <CardActionArea>
              <CardContent>
                <p className='text-2xl my-4 text-title'>
                  {post.frontmatter.title}
                </p>
                <p className='my-4 text-gray-700'>{post.frontmatter.date}</p>
                <p className='text-gray-700'>{post.frontmatter.description}</p>
              </CardContent>
            </CardActionArea>
            <CardActions className='text-center md:text-left'>
              <Button className='blogDate'>
                <span style={{ color: '#0070f3' }}>
                  {post.frontmatter.linkTitle}
                </span>
              </Button>
            </CardActions>
          </Card>
        </div>
      )
    })
    setPosts(blogElements)
  }, [data])

  return (
    <Layout>
      <main className='homeMain'>
        <div className='hero flex flex-col md:flex-row'>
          <div className='heroTitle mt-10 mx-auto text-4xl text-center md:text-7xl md:pt-32 md:ml-20 md:hidden'>
            Velvet Brown
          </div>
          <div className='mx-auto mb-10 text-center md:hidden'>
            International Tuba Soloist
          </div>
          <img
            className='md:w-80 lg:w-96 md:pl-8 2xl:w-5/12 2xl:pl-32'
            src={vb2}
          />
          <div className='flex flex-col w-screen'>
            <div className='heroTitle hidden md:flex md:mx-auto md:text-5xl md:pt-32 lg:text-6xl xl:ml-20 2xl:text-7xl'>
              Velvet Brown
            </div>
            <div className='hidden md:flex md:my-4 md:mx-auto md:text-2xl xl:ml-24'>
              International Tuba Soloist
            </div>
            <div className='text-xs max-w-xs mx-auto text-center mt-8 mb-4 md:mx-auto md:max-w-sm md:my-4 md:mt-20 md:text-md lg:text-lg lg:max-w-lg 2xl:max-w-xl 2xl:text-xl xl:ml-44'>
              <>
                ""Simply Velvet" brilliantly displays Velvet Brown's
                musicianship, virtuosity, and overall command of the tuba"
                -Potenza Music
              </>
            </div>
            <div className='text-xs max-w-xs mx-auto text-center mb-12 md:mx-auto md:max-w-sm md:mb-12 md:my-4 md:text-md md:mt-4 lg:text-lg lg:max-w-lg 2xl:max-w-xl 2xl:text-xl xl:ml-44'>
              <>
                "A very expressive player whose tone is focussed yet robust and
                lively in all registers" -American Record Guide
              </>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center my-12 max-h-24 xl:flex-row xl:w-full xl:justify-center xl:mx-auto'>
          <div className='flex items-center mb-8 xl:mb-0'>
            <a href='https://music.psu.edu/' target='_blank'>
              <img
                className='w-24 h-8 mx-4 md:w-32 md:h-11 md:mx-12 xl:w-60 xl:h-auto xl:mx-10 2xl:mx-14'
                src={psuaa}
              />
            </a>
            <a href='https://peabody.jhu.edu/' target='_blank'>
              <img
                className='w-24 mx-4 md:w-28 md:mx-12 xl:w-52 xl:mx-10 2xl:mx-14'
                src={peabody}
              />
            </a>
            <a
              href='https://www.facebook.com/Stiletto-Brass-Quintet-181425271909167'
              target='_blank'
            >
              <img
                className='w-20 mx-4 md:w-24 md:mx-12 xl:w-44 xl:mx-10 2xl:mx-14'
                src={stiletto}
              />
            </a>
          </div>
          <div className='flex items-center'>
            <a href='https://www.deniswick.com/' target='_blank'>
              <img
                className='w-12 mx-4 md:w-16 md:mx-12 xl:w-32 xl:mx-10 2xl:mx-14'
                src={wick}
              />
            </a>
            <a href='https://www.melton-meinl-weston.com/' target='_blank'>
              <img
                className='w-12 mx-4 md:w-16 md:mx-12 xl:w-28 xl:mx-10 2xl:mx-14'
                src={buffet}
              />
            </a>
          </div>
        </div>
        <div className='pageTitle mt-0'>
          <span>Recent</span>
        </div>
        <div>{posts}</div>
      </main>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          description
          link
          useLink
          linkTitle
        }
      }
    }
  }
`
